import React from "react"
import { Global, css } from "@emotion/core"

import commonStyles from "../lib/commonStyles"
import Footer from "../components/footer"
function Layout({ children }) {
  return (
    <>
      <Global
        styles={css`
          *,
          *:before,
          *:after {
            box-sizing: border-box;
            margin: 0;
            padding: 0;
          }
          ul {
            list-style: none;
          }
          a,
          a:link,
          a:visited {
            text-decoration: none;
            font-family: ${commonStyles.fonts.sans};
            outline: none;
          }
          h1,
          h2,
          h3,
          h4,
          h5,
          h6 {
            font-family: ${commonStyles.fonts.sans};
          }
          p {
            font-family: ${commonStyles.fonts.sans};
          }
        `}
      />
      {children}
      <Footer />
    </>
  )
}

export default Layout
