import React from "react"
import styled from "@emotion/styled"
import commonStyles from "../lib/commonStyles"

const FooterSection = styled.footer``

const BeforeFooter = styled.div`
  height: 90px;
  background-color: ${commonStyles.colors.darkGreen};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BeforeFooterTxt = styled.p`
  color: white;
  font-family: ${commonStyles.fonts.script};
  text-align: center;
  font-size: 32px;

  @media (max-width: 900px) {
    font-size: 3.8vw;
  }

  @media (max-width: 700px) {
    font-size: 28px;
  }

  @media (max-width: 410px) {
    font-size: 24px;
  }

  @media (max-width: 320px) {
    font-size: 22px;
  }
`

const FooterContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 75px 0 25px 0;
`

const Heading = styled.h3`
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 20px;
`

const Slogan = styled.p`
  color: grey;
  font-size: 20px;
  text-align: center;
  margin-bottom: 75px;
`

const ContactList = styled.ul`
  display: flex;
  justify-content: space-between;

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`

const Contact = styled.p`
  font-size: 20px;
  &:not(:last-child) {
    margin-right: 60px;
  }

  @media (max-width: 1100px) {
    font-size: 18px;
  }

  @media (max-width: 950px) {
    font-size: 16px;
  }

  @media (max-width: 860px) {
    margin-bottom: 30px;
    margin-right: 0 !important;
    font-size: 20px;
  }

  @media (max-width: 450px) {
    font-size: 18px;
  }

  @media (max-width: 390px) {
    font-size: 16px;
  }
`

const CopyrightSection = styled.div`
  background-color: black;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`
const Copyright = styled.p`
  color: grey;

  @media (max-width: 414px) {
    font-size: 15px;
  }

  @media (max-width: 390px) {
    text-align: center;
  }
`
function Footer() {
  const contactItems = [
    {
      name: "Email",
      value: "hello@holidaybirds.net",
    },
    {
      name: "Telephone",
      value: "5254 1655",
    },
    {
      name: "Office",
      value: "Baie Lascars, Rodrigues Island",
    },
  ]

  const contactItemsJsx = contactItems.map((item, index) => {
    return (
      <Contact key={`contactitem-${index}`}>
        {item.name}: <strong style={{ fontWeight: 500 }}>{item.value}</strong>
      </Contact>
    )
  })
  return (
    <FooterSection>
      <BeforeFooter>
        <BeforeFooterTxt>
          &ldquo;If you think adventure is dangerous, try routine, it's
          lethal&rdquo;
        </BeforeFooterTxt>
      </BeforeFooter>
      <FooterContent>
        <Heading>Holiday Birds</Heading>
        <Slogan>
          Your travel partner <br></br>in Mauritius & Rodrigues
        </Slogan>
        <ContactList>{contactItemsJsx}</ContactList>
      </FooterContent>
      <CopyrightSection>
        <Copyright>
          Website by Arsero Ltd — 
          <a
            style={{ color: "#00c0f3", fontWeight: 500 }}
            href="https://arsero.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Website design services
          </a>{" "}
          | All Rights Reserved &copy; <span>{new Date().getFullYear()}</span>
        </Copyright>
      </CopyrightSection>
    </FooterSection>
  )
}

export default Footer
