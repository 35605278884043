const commonStyles = {
  fonts: {
    sans: "Montserrat, sans-serif",
    script: "Kaushan Script, cursive",
  },
  colors: {
    darkGreen: "#269a8c",
    lightGreen: "#51e7d3",
    lightGrey: "#eeeaea",
  },
}

export default commonStyles
